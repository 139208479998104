import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import moment from 'moment'
import showdown from 'showdown'

const converter = new showdown.Converter({
    strikethrough: true
})

function slugify(text) {
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
  const to = "aaaaaeeeeeiiiiooooouuuunc------"

  const newText = text.split('').map(
    (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

  return newText
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/&/g, '-y-')           // Replace & with 'and'
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}


const NewsPreview = ({posts})=> {
    let activePosts = posts.filter((post) => {
        return post.expiresAt === null || moment(post.expiresAt).isAfter(moment());
    });
    return (
        <div className="news-preview">
            {activePosts.map(post => {
                let description = converter.makeHtml(post.description)
                const date = moment(post.publishedAt)
                const endslug = slugify(post.title)
                const slug = `/posts/${date.format("YYYY-MM-DD")}/${endslug}`
                if(post.externalLink === null) {
                    return (
                        <Link to={slug} className="news-card">
                            <div className="news-image">
                                <Img className="image" fluid={post.heroImage.documentFile.childImageSharp.fluid} />
                            </div>
                            <div className="news-card-body">
                                <small>{date.format("MMM D, YYYY")}</small>
                                <h3 className="title is-size-4">{post.title}</h3>
                                <div dangerouslySetInnerHTML={{__html: description}}></div>

                            </div>
                            <div className="news-card-footer">
                                <Link to={slug} className="button is-primary">READ MORE</Link>
                            </div>
                        </Link>
                    )
                } else {
                    return (
                        <a href={post.externalLink} className="news-card">
                            <div className="news-image">
                                <Img className="image" fluid={post.heroImage.documentFile.childImageSharp.fluid} />
                            </div>
                            <div className="news-card-body">
                                <small>{date.format("MMM D, YYYY")}</small>
                                <h3 className="title is-size-4">{post.title}</h3>
                                <div dangerouslySetInnerHTML={{__html: description}}></div>

                            </div>
                            <div className="news-card-footer">
                                <a href={post.externalLink} className="button is-primary">READ MORE</a>
                            </div>
                        </a>

                    )

                }
                })}
        </div>

    )
}

export default NewsPreview