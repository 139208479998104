import React from 'react'

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    let email
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  
    return (
      <div className="is-inline-block" style={{maxWidth: "400px", padding: '0 0.5rem'}}>
        {status === "sending" && <div className='status-window' style={{ color: "blue" }}>sending...</div>}
        {status === "error" && (
          <div className='status-window'
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div className='status-window'
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <div className="field has-addons">
        <div className="control">
        <input
          className="input"
          ref={node => (email = node)}
          type="email"
          placeholder="Enter your email"
        />
        </div>
          <div className="control">
          <button className="button button-unstyled is-warning" onClick={submit}>
            Submit
          </button>
          </div>
        </div>
      </div>
    );
  };

export default CustomForm;